import React, { Component } from "react";

import Accent from "../Accent";

import pdf from "./../../images/resourcePdf.png";
import doc from "./../../images/resourceDoc.png";
import ppt from "./../../images/resourcePpt.png";

import styles from "./styles.module.less";

class ResourcesDisplay extends Component {
    constructor(props) {
        super(props);
        this.resources = [
            {
                name: "30 Things That Can Affect Your Rate",
                imgLink: ppt,
                url: "/pdf/things-that-can-affect-rate.pdf",
                alt:
                    "30 Things That Can Affect Your Rate - Home Loan Resources | Bay Equity Home Loans",
            },
            {
                name: "First-time homeowner checklist",
                imgLink: doc,
                url: "/pdf/first-time-homeowner-checklist.pdf",
                alt:
                    "First-time Homeowner Checklist - Home Loan Resources | Bay Equity Home Loans",
            },
            {
                name: "Guide to Buy a Home",
                imgLink: pdf,
                url: "/pdf/guide-to-buy-a-home.pdf",
                alt:
                    "Guide to Buying a Home - Home Loan Resources | Bay Equity Home Loans",
            },
        ];
    }

    render() {
        return (
            <div>
                {this.resources.map((s, i) => {
                    return (
                        <div key={i}>
                            <a
                                href={s.url}
                                download
                                target="_blank"
                                rel="noopener noreferrer">
                                <div className={styles.resourceContainer}>
                                    <img
                                        alt={s.alt}
                                        className={styles.resourceImage}
                                        src={s.imgLink}
                                    />
                                    <div className={styles.resourceDescription}>
                                        {s.name || "Download"}
                                    </div>
                                </div>
                            </a>
                        </div>
                    );
                })}
            </div>
        );
    }
}

const ResourcesBe = () => {
    return (
        <section className={styles.container}>
            <div className={`${styles.inner} ${styles.helpModule}`}>
                <div className={styles.help}>
                    <div className={styles.content}>
                        <div>
                            <h1 className={styles.title}>Need some help?</h1>
                        </div>
                        <Accent align="left" />
                        <div className={styles.copy}>
                            That’s why we’re here. Download a variety of helpful
                            documents and resources to make your loan process
                            that much easier.
                        </div>
                    </div>
                    <div className={styles.resources}>
                        <ResourcesDisplay />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ResourcesBe;

import React from "react";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import { mapBlogs } from "../../lib/utility";

import Layout from "../../components/Layout";
import Header from "../../components/Header";
import IntroHeader from "../../components/IntroHeader";
import Calcs from "../../components/Calculators";
import FindLO from "../../components/FindLO";
import ResourceBe from "../../components/ResourceBe";
import BlogFiveBlock from "../../components/BlogFiveBlock";
import QuestionsBlock from "../../components/QuestionsBlock";
import Divider from "../../components/Divider";

import img from "../../images/tools_mark.png";

const ToolsResources = ({ data }) => {
    const header = (
        <Header active={["tools-and-resources"]} theme="blue-ribbon" />
    );
    const introHeader = "Tools and Resources";
    const alt =
        "Home Loan Resources - Tools and Resources Icon | Bay Equity Home Loans";
    const par =
        "Wherever you might be on your home-buying journey, consider this your cheat sheet. You’ll find multiple home loan calculators, home-buying checklists and other great tips and tools to help you along the way.";
    const blogHeader = "There’s always more on our blog";
    const { corpBlogs } = data;
    const { edges: corpBlobPosts = [] } = corpBlogs || {};
    const corporateBlogs = mapBlogs(corpBlobPosts);

    return (
        <Layout hasHero={false} header={header}>
            <Helmet>
                <title>
                    Home Loan Resources - Mortgage Resources | Bay Equity Home
                    Loans
                </title>
                <meta
                    name="description"
                    content="Use the home loan resources on this page to navigate your home-buying journey. From calculators to checklists and other great tips, we’re here to get you home."
                />
                <script
                    src={`https://embed.signalintent.com/js/embedded.js?org-guid=${process.env.GATSBY_SIGNAL_INTENT_ORG_GUID}`}></script>
            </Helmet>
            <IntroHeader header={introHeader} par={par} img={img} alt={alt} />
            <Divider />
            <Calcs
                showCalculatorMortgage={true}
                showCalculatorRentVsBuy={true}
                showCalculatorHomeAffordability={true}
                showCalculatorRefinance={true}
                showCalculatorRequiredIncome={true}
            />
            <Divider />
            <FindLO />
            <Divider />
            <ResourceBe />
            <Divider />
            {corporateBlogs.length >= 5 && (
                <BlogFiveBlock
                    header={blogHeader}
                    blogs={corporateBlogs}
                    showBlogs={true}
                />
            )}
            <QuestionsBlock />
        </Layout>
    );
};
export default ToolsResources;

export const pageQuery = graphql`
    query ToolsPageQuery {
        corpBlogs: allBlog(
            limit: 10
            sort: { fields: [date], order: DESC }
            filter: {
                type: { eq: "corporate" }
                tags: { in: ["Tools and Resources"] }
            }
        ) {
            edges {
                node {
                    id
                    html
                    slug
                    title
                    date
                    feature
                    categories
                    image
                    author {
                        slug
                        profile {
                            name
                        }
                        gallery {
                            photo
                        }
                    }
                    date
                    feature
                }
            }
        }
    }
`;

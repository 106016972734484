import React from "react";
import LinkButton from "./../../components/LinkButton";
import icon from "./../../images/home-loan-faq.png";
// import bgImage from "./../../images/toolsBg.png"; //
import styles from "./styles.module.less";

const QuestionsBlock = ({ header, par, bgimg, btn, bg }) => {
    return (
        <section className={styles.QuestionsBlock}>
            <div className={styles.inner}>
                <div className={styles.whiteSquare}>
                    <img
                        className={styles.icon}
                        src={icon}
                        alt="Still Have Questions About Home Loans? - Home Loan Resources | Bay Equity Home Loans"
                    />
                    <h1 className={styles.title}>Still have questions?</h1>
                    <p>Find answers to the most frequently asked questions.</p>
                    <LinkButton label="View FAQ" href="/home-loan-faqs" />
                </div>
            </div>
        </section>
    );
};

export default QuestionsBlock;
